@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Fontes */
@font-face {
  font-family: 'Nunito';
  font-weight: 300; /* Light */
  src: local('Nunito-Light'),
    url(../assets/fonts/Nunito-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-weight: 400; /* Regular */
  src: local('Nunito-Regular'),
    url(../assets/fonts/Nunito-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-weight: 600; /* SemiBold */
  src: local('Nunito-SemiBold'),
    url(../assets/fonts/Nunito-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-weight: 700; /* Bold */
  src: local('Nunito-Bold'),
    url(../assets/fonts/Nunito-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-weight: 800; /* ExtraBold */
  src: local('Nunito-ExtraBold'),
    url(../assets/fonts/Nunito-ExtraBold.ttf) format('truetype');
}

* {
  font-family: 'Nunito', 'Arial';
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.scroll-1::-webkit-scrollbar {
  width: 8px;
}
.scroll-1::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #888;
}
.scroll-1::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scroll-1 .cm-scroller::-webkit-scrollbar {
  width: 8px;
}
.scroll-1 .cm-scroller::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #888;
}
.scroll-1 .cm-scroller::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scroll-2::-webkit-scrollbar {
  width: 0px;
}
.scroll-2::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #13161b;
}
.scroll-2::-webkit-scrollbar {
  width: 0px;
  height: 5px;
}

.dot-flashing:nth-child(1) {
  animation: dotFlashing 0.8s infinite linear alternate;
  animation-delay: 0s;
}
.dot-flashing:nth-child(2) {
  animation: dotFlashing 0.8s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing:nth-child(3) {
  animation: dotFlashing 0.8s infinite linear alternate;
  animation-delay: 1s;
}

.dot-flashing:nth-child(4) {
  animation: dotFlashing 0.8s infinite linear alternate;
  animation-delay: 1.5s;
}
.dot-flashing:nth-child(5) {
  animation: dotFlashing 0.8s infinite linear alternate;
  animation-delay: 2s;
}
.dot-flashing:nth-child(6) {
  animation: dotFlashing 0.8s infinite linear alternate;
  animation-delay: 2.5s;
}

@keyframes dotFlashing {
  0% {
    background-color: #ddd;
  }
  50%,
  100% {
    background-color: #00dd80;
  }
}

.rocketAnimation {
  animation: rocketAnimation 0.8s infinite linear;
  animation-duration: 2s;
  position: relative;
}

@keyframes rocketAnimation {
  0% {
    transform: translateY(-10%);
  }

  30% {
    transform: translateY(-15%);
  }
  90%,
  100% {
    transform: translateY(-10%);
  }
}

input:-webkit-autofill.autocompleteInput,
input:-webkit-autofill:hover.autocompleteInput,
input:-webkit-autofill:active.autocompleteInput,
input:-webkit-autofill:focus.autocompleteInput {
  background-color: #222 !important;
  color: #fff !important;
  -webkit-box-shadow: 0 0 0 1000px #222 inset !important;
  box-shadow: 0 0 0 1000px #222 inset !important;
  -webkit-text-fill-color: #fff !important;
}

@keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 50%;
  }
}

.animate-progress-bar {
  animation: progressBar 2s ease-in-out infinite;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in-delay {
  animation: fade-in 1s ease-in-out;
}

.custom-input-color {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 26px;
  height: 26px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.custom-input-color::-webkit-color-swatch {
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px #00000026;
}
.custom-input-color::-moz-color-swatch {
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px #00000026;
}

/* React Datepicker component */
.custom-datepicker > div.react-datepicker-wrapper {
  width: 100%;
}
/* React Datepicker component */

/* React RichText component */
.DraftEditor-editorContainer .public-DraftEditor-content h1 {
  font-size: 2em !important;
  font-weight: bolder !important;
}

.DraftEditor-editorContainer .public-DraftEditor-content h2 {
  font-weight: bolder !important;
  font-size: 1.5em !important;
}

.DraftEditor-editorContainer .public-DraftEditor-content h3 {
  font-weight: bolder !important;
  font-size: 1.7em !important;
}

.DraftEditor-editorContainer .public-DraftEditor-content h4 {
  font-size: 1em !important;
  font-weight: bolder !important;
}

.DraftEditor-editorContainer .public-DraftEditor-content h5 {
  font-size: 0.83em !important;
  font-weight: bolder !important;
}

.DraftEditor-editorContainer .public-DraftEditor-content h6 {
  font-size: 0.67em !important;
  font-weight: bolder !important;
}
.public-DraftStyleDefault-block {
  margin: 0 !important;
}
/* React RichText component */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #e5e7eb;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover,
.dark input:-webkit-autofill:focus,
.dark textarea:-webkit-autofill,
.dark textarea:-webkit-autofill:hover,
.dark textarea:-webkit-autofill:focus,
.dark select:-webkit-autofill,
.dark select:-webkit-autofill:hover,
.dark select:-webkit-autofill:focus {
  border: 1px solid #4b5563;
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px red inset;
  transition: background-color 5000s ease-in-out 0s;
}

.rdw-editor-toolbar {
  gap: 20px;
}
.rdw-option-active {
  border-color: #00dd80 !important;
}
.custom-toolbar .rdw-option-wrapper {
  height: 28px;
  width: 28px;
  box-shadow: none !important;
}

.dark-custom-toolbar .rdw-option-wrapper {
  color: white;
  background: #303740;
  border-color: #576073;
}

.dark-custom-toolbar .rdw-option-wrapper img {
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
}
.rdw-link-modal {
  height: auto !important;
  left: auto !important;
  right: 5px !important;
}

.rdw-link-modal button {
  border: none;
  box-shadow: none !important;
  background: #e5e7eb;
}

.rdw-link-modal button:first-child {
  border: none;
  background: #00dd80;
}
.rdw-link-decorator-icon {
  display: none;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #00dd80;
  cursor: pointer;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

input[type='range']::-moz-range-thumb {
  -moz-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #00dd80;
  cursor: pointer;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 181, 63, 0.16);
  border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 181, 63, 0.16);
}

div.label-preview::before {
  content: 'Preview';
  position: absolute;
  top: 0;
  left: -30px;
  transform: rotate(-45deg);
  background-color: #576073;
  color: #21262d;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  width: 133px;
  padding-left: 24px;
}
